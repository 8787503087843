<template>
  <div>
    <div class="bannder">
      <div class="ban">
        <div class="card" v-if="showLogin">
          <p>用户登录</p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm login"
            key="1"
          >
            <el-form-item prop="tel">
              <el-input
                style="width: 100%"
                class="ipt"
                placeholder="请输入注册时的账号或手机号"
                @blur="inspectPhone"
                v-model.trim="ruleForm.tel"
              >
                <i slot="prefix" class="iconfont icon-denglu"></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="pwassWord">
              <el-input
                style="width: 100%"
                class="ipt"
                placeholder="请输入密码"
                v-model.trim="ruleForm.pwassWord"
                show-password
              >
                <i slot="prefix" class="iconfont icon-mimasuo"></i>
              </el-input>
            </el-form-item>

            <el-form-item class="yanzheng" prop="verification">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-input
                    class="ipts"
                    placeholder="请输入验证码"
                    v-model.trim="ruleForm.verification"
                    @keyup.enter.native="loginEnt"
                  >
                    <i slot="prefix" class="iconfont icon-zhiliang"></i>
                  </el-input>
                </el-col>
                <el-col :span="12">
                  <img
                    :src="imgs"
                    alt="图片生成中..."
                    title="看不清楚? 点击更换"
                    @click="info"
                  />
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <div class="rumberme">
            <!-- <el-checkbox v-model="ruleForm.checked">记住我</el-checkbox> -->
            <span @click="showLogin = false">忘记密码?</span>
          </div>
          <div class="btn">
            <el-button
              type="primary"
              class="btns"
              :disabled="disabled2"
              :loading="fullscreenLoading"
              @click="onSubmit"
              >登录</el-button
            >
          </div>
          <!-- v-loading.fullscreen.lock="fullscreenLoading" -->
          <div class="sogin" @click="register">
            <i class="iconfont icon-fangxiang-copy"></i>
            <span>立即注册</span>
          </div>
        </div>
        <div class="card" v-else>
          <p>忘记密码</p>
          <el-form
            :model="ruleForm1"
            :rules="rules1"
            ref="ruleForm1"
            class="demo-ruleForm login"
            key="2"
          >
            <el-form-item prop="tel">
              <el-input
                style="width: 100%"
                class="ipt"
                placeholder="请输入注册的手机号或账号"
                @blur="inspectPhone"
                v-model.trim="ruleForm1.tel"
              >
                <i slot="prefix" class="iconfont icon-denglu"></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="passWord">
              <el-input
                style="width: 100%"
                class="ipt"
                placeholder="设置新密码"
                v-model.trim="ruleForm1.passWord"
                show-password
              >
                <i slot="prefix" class="iconfont icon-mimasuo"></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="passWord1">
              <el-input
                style="width: 100%"
                class="ipt"
                placeholder="重复新密码"
                v-model.trim="ruleForm1.passWord1"
                show-password
              >
                <i slot="prefix" class="iconfont icon-mimasuo"></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="verification">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-input
                    placeholder="请输入验证码"
                    v-model.trim="ruleForm1.verification"
                  >
                    <i slot="prefix" class="iconfont icon-zhiliang"></i>
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <!-- <div class="box" v-html="html"></div> -->
                  <el-button
                    type="primary"
                    class="btns"
                    v-html="html"
                    :disabled="disabled"
                    @click="getVer"
                  ></el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <span class="logins" @click="showLogin = true">登录</span>
          <div class="btn sure">
            <el-button
              type="primary"
              :disabled="disabled1"
              class="btns"
              @click="onSure"
              >确认</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import loginApi from "../../api/login/login";
// import qs from "qs";

import axios from "axios";
import { mapMutations } from "vuex";
import api from "../../api/index/index";
import { username } from "../../api/myAuth";
export default {
  name: "Login",
  data() {
    // 手机验证
    // var validateMobilePhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("手机号不可为空"));
    //   } else {
    //     if (value !== "") {
    //       var reg = /^1[3456789]\d{9}$/;
    //       if (!reg.test(value)) {
    //         callback(new Error("请输入有效的手机号码"));
    //       }
    //     }
    //     callback();
    //   }
    // };

    // 密码验证
    var validatePass = (rule, value, callback) => {
      // const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      if (!value) {
        return callback(new Error("请输入密码"));
        // } else if (pwdReg.test(value)) {
      } else {
        callback();
      }
      // else {
      //   callback(new Error("请输入包含数字字母的不低于8位数的密码"));
      // }
    };
    // 二次密码验证
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm1.passWord) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      disabled1: false,
      disabled2: false,
      imgs: "",
      disabled: false,
      html: "获取验证码",
      showLogin: true,
      // 登录
      ruleForm: {
        tel: "", // 手机号
        pwassWord: "", //密码
        verification: "", // 验证码
        checked: false, // 记住我
      },
      rules: {
        // tel: [
        //   {
        //     required: true,
        //     validator: validateMobilePhone,
        //     trigger: "blur",
        //   },
        // ],
        tel: [
          {
            required: true,
            message: "账号或者手机号不能为空",
            trigger: "blur",
          },
        ],
        pwassWord: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        verification: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
      // 找回密码
      ruleForm1: {
        tel: "",
        passWord: "",
        passWord1: "",
        verification: "",
      },
      rules1: {
        // tel: [
        //   {
        //     required: true,
        //     validator: validateMobilePhone,
        //     trigger: "blur",
        //   },
        // ],
        tel: [
          {
            required: true,
            message: "账号或者手机号不能为空",
            trigger: "blur",
          },
        ],
        passWord: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        passWord1: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        verification: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },

      //指令加载方式
      fullscreenLoading: false,
      canEnt: true, // 未注册回车不调接口
    };
  },
  created() {
    // 获取图片验证码
    this.info();
  },
  methods: {
    ...mapMutations(["getCurrentInfo"]),
    inspectPhone() {
      let phone = "";
      if (this.showLogin) {
        phone = this.ruleForm.tel;
      } else {
        phone = this.ruleForm1.tel;
      }
      if (phone !== "") {
        api
          .inspectPhones(phone)
          .then((res) => {
            // console.log("检测手机号是否存在", res);
            if (res.success) {
              if (res.data === 1) {
                this.canEnt = true;
                this.disabled1 = this.disabled2 = this.disabled = false;
              } else {
                this.canEnt = false;
                this.disabled1 = this.disabled2 = this.disabled = true;
                // this.$message.warning("当前账号或者手机号尚未注册");
                this.$confirm(
                  "当前账号或者手机号尚未注册，请确定是否跳转注册",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$router.push({ path: "/register" });
                    this.ruleForm.tel = "";
                    this.ruleForm1.tel = "";
                  })
                  .catch(() => {
                    return;
                  });
                // this.$router.push({ path: "/register" });
                // this.ruleForm.tel = "";
                // this.ruleForm1.tel = "";
              }
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },
    loginEnt() {
      console.log("测试登录");
      if (this.canEnt) {
        this.onSubmit();
      }
    },
    // 获取后台验证码
    info() {
      // 获取时间戳
      var newTime = new Date().getTime().toString();
      // this.imgs = `http://cpsapi.yy12365.com/code/make?t=${newTime}`;
      this.imgs = axios.defaults.baseURL + `/code/make?t=${newTime}`;
      // console.log(axios.defaults.baseURL)
    },
    // 确认修改密码
    onSure() {
      let datas = this.ruleForm1;
      let data = {
        phone: datas.tel,
        verificationCode: datas.verification,
        newPassword: datas.passWord,
      };
      // 表单验证
      this.$refs.ruleForm1.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.$http
            .post(
              "/login/updatePwd?phone=" +
                data.phone +
                "&verificationCode=" +
                data.verificationCode +
                "&newPassword=" +
                data.newPassword
            )
            .then((res) => {
              console.log(res);
              if (res.data.message == "成功！") {
                console.log(data);
                this.$message({
                  message: "密码修改成功",
                  type: "success",
                });
                //切换登录页面
                this.showLogin = true;
                // this.$router.push({ path: "login" });
              } else {
                this.$message({
                  message: res.data.message,
                  type: "warning",
                });
              }
            });
          // alert("密码修改成功");
        } else {
          // console.log("登录失败");
          // alert("密码修改失败");
          this.$message.error("密码修改失败");
          return false;
        }
      });
    },
    //获取验证码
    getVer() {
      let datas = this.ruleForm1;
      console.log(this.ruleForm1.tel);
      let data = {
        phone: datas.tel,
        management: "mallUse",
      };

      // 倒计时
      if (data.phone == "") {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
        });
      } else {
        // this.html = `${cont}秒后重新获取`;

        //获取验证码
        this.$http
          .post(
            "/login/sendSms?phone=" +
              data.phone +
              "&management=" +
              data.management
          )
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              console.log(data);
              this.$message({
                message: "获取成功",
                type: "success",
              });
              var cont = 60;
              let timer = setInterval(() => {
                --cont;
                if (cont === 0) {
                  this.disabled = false;
                  this.html = "获取验证码";
                  clearInterval(timer);
                  return;
                } else {
                  this.html = `${cont}秒后重新获取`;
                }
              }, 1000);
            } else {
              this.$message({
                message: res.data.message,
                type: "warning",
              });
            }
          });
      }
    },
    // 登录
    onSubmit() {
      // 表单验证
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          console.log(this.ruleForm);
          let datas = this.ruleForm;
          let data = {
            // code: datas.verification.toUpperCase(),
            code: datas.verification,
            userName: datas.tel,
            pwd: datas.pwassWord,
          };

          // setTimeout(() => {
          this.$http
            .post("/login/login", data)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                setTimeout(() => {
                  this.fullscreenLoading = false;
                }, 2000);
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                this.$store.commit("getCurrentInfo", res.data.data.user);
                window.sessionStorage.setItem(
                  "currentInfo",
                  JSON.stringify(res.data.data.user)
                );
                let userInfos = {
                  haveERP: res.data.data.THIS_MALL_HAVEERP,
                  phone: res.data.data.user.phone,
                  username: res.data.data.user.username,
                  userfatherid: res.data.data.user.userfatherid,
                };
                window.sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(userInfos)
                );
                // window.sessionStorage.setItem(
                //   "cookie",
                //   "custom.session=b455bd39-fce3-4e97-863c-f1636626e28b"
                // );
                window.sessionStorage.setItem("nav", 0);
                this.init();
                this.$router.push({ path: "index" });
              } else {
                setTimeout(() => {
                  this.fullscreenLoading = false;
                }, 2000);
                this.$message.error("登录失败,请检查账号，密码，验证码");
                this.info();
              }
            })
            .catch((error) => {
              console.error("Error", error);
              this.$message.error("登录失败，请确认填写的信息正确");
              setTimeout(() => {
                this.fullscreenLoading = false;
              }, 2000);
              this.info();
            });
          // }, 2000);
        } else {
          this.$message.warning("当前验证未通过，请认真填写信息");
          //   this.info();
          return false;
        }
      });
    },
    // 注册
    register() {
      this.$router.push({ path: "/register" });
    },
    init() {
      // api
      //   .getHomeInfo()
      //   .then((res) => {
      //     console.log("获取商城信息", res);
      //     if (res.success) {
      //       this.homeInfo = res.data.list[0];
      //       // 存储当前商城信息到浏览器
      //       // console.log("商城信息111111", this.homeInfo);
      //       username(this.homeInfo);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error", error);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.bannder {
  width: 100%;
  height: 550px;
  /*background: url("../../static/imgs/index_banner02.jpg") no-repeat center;*/
  background: url("../../static/imgs/bg.gif") no-repeat center;
  background-size: 100% 100%;

  .ban {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;

    .card {
      position: absolute;
      top: 65px;
      right: 0;
      box-sizing: border-box;
      padding: 10px 15px;
      width: 300px;
      height: 380px;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 20px;

      p {
        text-align: center;
        padding: 10px 0;
        font-size: 20px;
        font-weight: 600;
      }

      .demo-ruleForm {
        margin-top: 10px;
      }

      i {
        font-size: 20px;
      }

      .yanzheng {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          display: block;
          width: 120px;
          height: 40px;
          border: 1px solid #808080;
        }
      }

      .rumberme {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          font-size: 14px;
          color: #808080;
          cursor: pointer;
        }

        span:hover {
          color: #f00;
          text-decoration: underline;
        }
      }

      .sogin {
        float: right;
        margin-top: 10px;
        display: flex;
        font-size: 14px;
        color: #005cc8;
        cursor: pointer;
      }
      .login {
        ::v-deep.el-form-item__content .el-input {
          margin-right: 0;
        }
      }
      .btn {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        // background-color: #0289f3;
        color: #fff;
        font-weight: 600;
        border-radius: 10px;
        cursor: pointer;
        .btns {
          width: 100%;
        }
      }

      .logins {
        position: absolute;
        right: 20px;
        bottom: 60px;
        float: right;
        color: #0289f3;
        cursor: pointer;
        font-size: 14px;
      }

      .sure {
        margin-top: 35px;
      }
      .btns {
        padding: 12px 20px;
      }
    }
  }
}
</style>
